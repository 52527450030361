export enum WorkerMessageType {
  START = 'START',
  SET_LOG_LEVEL = 'SET_LOG_LEVEL',
  ERROR = 'ERROR',
  DATA_UPDATE = 'DATA_UPDATE',
  SYNCHRONISED_DATA_UPDATE = 'SYNCHRONISED_DATA_UPDATE',
  PING = 'PING',
  PONG = 'PONG',
  DISPATCH = 'DISPATCH',
  DISPATCH_WITH_PROGRESS = 'DISPATCH_WITH_PROGRESS',
  RESPONSE = 'RESPONSE',
  HANDLE_LOGIN = 'HANDLE_LOGIN',
  PROGRESS = 'PROGRESS',
  CANCEL = 'CANCEL',
  CANCELLED = 'CANCELLED',
  TYPING = 'TYPING',
  SET_ACTIVE_TRACK_ID = 'SET_ACTIVE_TRACK_ID',
  SET_ACTIVE_ENTRY_ID = 'SET_ACTIVE_ENTRY_ID',
  SET_ACTIVE_MINI_APP_ID = 'SET_ACTIVE_MINI_APP_ID',
  SET_MEETING_TRACK_ID = 'SET_MEETING_TRACK_ID',
  CALL_MESSAGE = 'CALL_MESSAGE',
  SET_RECENTLY_VIEWED_TRACK_IDS = 'SET_RECENTLY_VIEWED_TRACK_IDS',
  SET_USER_ACTIVE = 'SET_USER_ACTIVE',
  SET_GUEST_MEETING_RECORD_ENTRY = 'SET_GUEST_MEETING_RECORD_ENTRY',
  GUEST_DATA_UPDATE = 'GUEST_DATA_UPDATE',
  IOS_RECONNECT_LIVEUSER = 'IOS_RECONNECT_LIVEUSER',
  THIRD_PARTY_MEETING_DETAILS = 'THIRD_PARTY_MEETING_DETAILS',
  CHECK_FOR_SERVICE_WORKER_UPDATE = 'CHECK_FOR_SERVICE_WORKER_UPDATE',
  SET_ACTIVE_GUEST_TRACK_ID = 'SET_ACTIVE_GUEST_TRACK_ID',
  TRIGGER_GUEST_ONLINE_UPDATE = 'TRIGGER_GUEST_ONLINE_UPDATE',
}
